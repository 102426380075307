import React from 'react'
import { useIntl } from 'gatsby-plugin-intl';

import Footer from '../components/footer'
import Header from '../components/header'
import about1 from '../slider/about2.jpg';
import about2 from '../slider/about3.jpg';
import favicon from '../icons/favicon.ico';
import { Helmet } from "react-helmet";

export default (props) => {
    const intl = useIntl();
    return (
        <div className="about-page">
            <Helmet>
                <meta property="og:image" content="https://nanonet.hr/cover.jpg"/>
                <meta property="og:image:secure_url" content="https://nanonet.hr/cover.jpg"/>
                <title>{intl.formatMessage({ id: "header.about" })}</title>
                <link rel="icon" href={favicon} />
            </Helmet>
            <Header lang={props.pathContext.language}/>

            <div className="about" style={{margin: '0 10%'}}>

                <div className="text main-title">{intl.formatMessage({ id: "aboutPage.title" })}</div>

                <div className="about-row">
                    <div className="about-col">
                        <div className="text content">
                            {intl.formatMessage({ id: "aboutPage.paragraph1" })}
                        </div>
                        <div className="text content">
                            {intl.formatMessage({ id: "aboutPage.paragraph2" })}
                        </div>
                        <div className="cover">
                            <img alt="" src={about1} />
                        </div>
                    </div>
                    <div className="about-col">
                        <div className="cover cover-2">
                            <img alt="" src={about2} />
                        </div>
                        <div className="text content">
                            {intl.formatMessage({ id: "aboutPage.paragraph3" })}
                        </div>
                        <div className="text content">
                            {intl.formatMessage({ id: "aboutPage.paragraph4" })}
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
}
